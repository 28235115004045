<template>
  <div>
    <div class="row">
      <div class="col-xxl-8">
        <div class="card card-custom gutter-b example example-compact" v-if="isReady">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">{{ $t('User.user_detail') }}</h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <div class="row">
              <div class="col-xl-6">
                <b-form-group id="fieldset-1" type="email" description="" :label="$t('User.email')" label-for="input-1">
                  <b-form-input
                    :disabled="mode == 'edit' ? true : false"
                    id="input-1"
                    v-model="post.email"
                    trim
                    :placeholder="$t('User.email')"
                    :state="state_email"
                  ></b-form-input>
                </b-form-group>
                <b-form-group id="fieldset-1" description="" :label="$t('User.password')" label-for="input-1" trim>
                  <b-form-input
                    id="input-1"
                    v-model="post.password"
                    trim
                    type="password"
                    :placeholder="$t('User.password')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="fieldset-1"
                  :label="$t('User.permission')"
                  label-for="input-1"
                  v-slot="{ariaDescribedby}"
                >
                  <b-form-checkbox-group
                    v-model="post.permissions"
                    :options="permission_list"
                    class="mb-3"
                    value-field="slug"
                    text-field="name"
                    :aria-describedby="ariaDescribedby"
                    switches
                    stacked
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-xl-6">
                <b-form-group id="fieldset-1" description="" :label="$t('User.first_name')" label-for="input-1">
                  <b-form-input
                    id="input-1"
                    v-model="post.first_name"
                    trim
                    :placeholder="$t('User.first_name')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group id="fieldset-1" description="" :label="$t('User.last_name')" label-for="input-1">
                  <b-form-input
                    id="input-1"
                    v-model="post.last_name"
                    trim
                    :placeholder="$t('User.last_name')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group id="fieldset-1" description="" :label="$t('User.address')" label-for="input-1">
                  <b-form-input
                    id="input-1"
                    v-model="post.address"
                    trim
                    :placeholder="$t('User.address')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group id="fieldset-1" description="" :label="$t('User.phone')" label-for="input-1">
                  <b-form-input id="input-1" v-model="post.phone" trim :placeholder="$t('User.phone')"></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>

        <b-overlay :show="isSaving" no-wrap> </b-overlay>
      </div>

      <div class="col-xxl-4">
        <div class="card card-custom gutter-b" v-if="isReady">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">
                {{ $t('User.status') }}
              </h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-form-group id="fieldset-1" label="สถานะ  " label-for="input-1">
              <b-form-select v-model="post.status" :options="status_list"></b-form-select>
            </b-form-group>
            <b-button variant="primary" class="mb-5" block @click="savePost">Save</b-button>
          </div>
          <!--end::Body-->
          <b-overlay :show="isSaving" no-wrap> </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {SAVE_POST, QUERY, GET_GROUP, GET_PERM, UPLOAD_FILE} from '../services.js';

const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  name: 'List',
  components: {},
  data() {
    return {
      isReady: false,
      isSaving: false,
      isUploading: false,
      mode: null,
      post: {
        id: 0,
        username: '',
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        address: '',
        phone: '',
        status: 0,
        group: 0,
        group_name: '',
        permissions: [],
      },
      permission_list: [],
      pictureFile: null,
      imageSrc: null,
      status_list: [
        {
          value: 0,
          text: 'InActive',
        },
        {
          value: 1,
          text: 'Active',
        },
      ],
      groups_list: [],
      state_email: null,
      state_group: null,
    };
  },
  mounted() {
    //get data
    this.getData();

    this.$store.dispatch(SET_BREADCRUMB, [{title: 'User', route: {name: 'user.user'}}, {title: 'Edit'}]);
  },
  methods: {
    getData() {
      this.$store
        .dispatch(GET_PERM)
        // go to which page after successfully login
        .then((res) => {
          this.permission_list = res.data;
        })
        .catch((error) => {
          // console.log(error);
        });
      if (this.$route.name == 'user.edit') {
        this.mode = 'edit';
        this.id = this.$route.params.id;
        this.$store
          .dispatch(QUERY, {id: this.id})
          // go to which page after successfully login
          .then((res) => {
            this.post = res.data[0];
            this.isReady = true;
          })
          .catch((error) => {
            // console.log(error);
          });
      } else {
        this.isReady = true;
        this.mode = 'add';
        this.post.row = this.$route.params.row;
      }
    },
    savePost(mode) {
      return new Promise((resolve, reject) => {
        if (this.post.email == '') {
          if (this.post.email == '') {
            this.state_email = false;
          }
          this.notify('danger', 'Error!', 'Please complete the form');
          reject('Please complete the form');
        } else {
          this.state_email = true;
          this._savePost(mode);
          resolve();
        }
      });
    },
    _savePost(mode) {
      this.isSaving = true;
      this.$store
        .dispatch(SAVE_POST, this.post)
        // go to which page after successfully login
        .then((res) => {
          if (res.status) {
            this.post = res.data;

            this.notify('success', 'Successfully!', 'Post Saved.');
            this.$router.push({
              name: 'user.list',
            });
          }
          // console.log('Save post');
          this.isSaving = false;
        })
        .catch((error) => {
          this.isSaving = false;
          // console.log(error);
        });
    },
    notify(variant = null, title, text) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
